/**
 * @author EdenCha <eden@nurigo.net>
 */
import React from 'react'
import { Map } from 'immutable'
import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import config from 'config'
import { requestHandler } from 'lib'
import {
  MdShoppingCart as MdShoppingCartIcon,
  MdHelpCenter as MdHelpCenterIcon
} from 'react-icons/md'
import { RiPlantFill as RiPlantFillIcon } from 'react-icons/ri'
import { HiLink as HiLinkIcon } from 'react-icons/hi'
import { ReactComponent as CtaLogo } from 'static/image/logo/cta_logo.svg'
import { ReactComponent as RcsLogo } from 'static/image/logo/rcs_logo.svg'
import { ReactComponent as NaverTalkTalkLogo } from 'static/image/logo/naver_talktalk_logo_2.svg'

// const { apiHost: configApiHost } = config
// const ahid = jwt.decode(cookies.get('AHID')) || {}
// const apiHost = ahid.apiHost || configApiHost
const createRedux = requestHandler.createRedux

const initialState = Map({
  auth: undefined,
  loadComponents: [],
  loadComponentCount: 0,
  loadedComponents: [],
  token: jwt.decode(cookies.get('CSAK')),
  menus: [
    {
      headerTitle: '콘솔',
      list: [
        {
          name: '대시보드',
          icon: 'dashboard',
          link: '/dashboard'
        },
        {
          name: '시작하기',
          icon: 'flight_takeoff',
          link: '/start'
        },
        {
          name: '서비스 튜토리얼',
          icon: <RiPlantFillIcon />,
          link: '/service-tutorial'
        }
      ]
    },
    {
      headerTitle: '메시지',
      list: [
        {
          name: '메시지 보내기',
          icon: 'email',
          link: '/purplebook'
        },
        {
          // 표시될 메뉴 이름
          name: '메시지 통계',
          // 표시될 아이콘
          icon: 'assessment',
          link: '/stat'
        },
        {
          name: '발송 내역',
          icon: 'view_list',
          link: '/message-log',
          exact: false
        },
        {
          name: '사용자 발송내역 관리',
          icon: '',
          link: '/admin/message-log',
          exact: false,
          admin: true
        },
        {
          name: '발신번호 관리',
          icon: 'contact_phone',
          link: '/senderids'
        },
        {
          name: '사용자 발신번호 관리',
          icon: '',
          link: '/admin/senderids',
          admin: true
        },
        {
          name: '일일 발송량 제한',
          icon: 'speed',
          link: '/quota'
        },
        {
          name: '일일 발송량 제한 관리',
          icon: '',
          link: '/admin/quota',
          admin: true
        },
        {
          name: '수신 거부 목록',
          icon: 'cancel_schedule_send',
          link: '/blocklist'
        }
      ]
    },
    {
      headerTitle: '잔액 / 요금',
      list: [
        {
          name: '충전 / 관리',
          icon: 'credit_card',
          link: '/balance'
        },
        {
          name: '충전 내역',
          icon: 'event',
          link: '/balance/history',
          exact: true
        },
        {
          name: '후불요금제 신청',
          icon: 'assignment',
          link: '/deferred-payment/request',
          showAppLayout: false
        },
        {
          name: '사용자 잔액 관리',
          icon: '',
          link: '/admin/balance',
          admin: true
        },
        {
          name: '사용자 잔액 사용 내역',
          icon: '',
          link: '/admin/balance/history',
          admin: true
        },
        {
          name: '출금 요청 관리',
          icon: '',
          link: '/admin/withdrawal/history',
          admin: true
        },
        {
          name: '사용자 단가 관리',
          icon: '',
          link: '/admin/pricing',
          admin: true
        },
        {
          name: '마이사이트 단가 관리',
          icon: '',
          link: '/admin/mysites/pricing',
          admin: true
        },
        {
          name: '사업자정보 관리',
          icon: '',
          link: '/admin/companies',
          admin: true
        },
        {
          name: '세금계산서 관리',
          icon: '',
          link: '/admin/taxinvoice',
          admin: true
        },
        {
          name: '후불요금제 사용자목록',
          icon: 'assignment',
          link: '/admin/deferred-payment/list',
          admin: true,
          showAppLayout: false
        }
      ]
    },
    {
      headerTitle: '외부 채널 발송',
      list: [
        {
          name: '카카오톡 채널',
          icon: (
            <CtaLogo
              style={{
                width: '1em',
                height: '1em',
                verticalAlign: 'bottom'
              }}
            />
          ),
          link: '/kakao/plus-friends'
        },
        {
          name: '카카오 알림톡 템플릿',
          icon: 'assignment',
          link: '/kakao/templates'
        },
        {
          name: '네이버톡톡 아이디',
          link: '/naver/agents',
          icon: (
            <NaverTalkTalkLogo
              style={{
                width: '1em',
                height: '1em',
                verticalAlign: 'bottom'
              }}
            />
          )
        },
        {
          name: '네이버톡톡 템플릿',
          link: '/naver/templates',
          icon: 'assignment'
        },
        {
          name: 'RCS 브랜드',
          link: '/rcs/brands',
          icon: (
            <RcsLogo
              style={{
                width: '1.1em',
                height: '1.1em',
                verticalAlign: 'bottom'
              }}
            />
          )
        },
        {
          name: 'RCS 템플릿',
          link: '/rcs/templates',
          icon: 'assignment'
        },
        {
          name: '채널 그룹 관리',
          icon: '',
          link: '/admin/kakao/groups',
          admin: true
        },
        {
          name: '플러스친구 관리',
          icon: '',
          link: '/admin/kakao/plus-friends',
          admin: true
        },
        {
          name: '알림톡 템플릿 관리',
          icon: '',
          link: '/admin/kakao/templates',
          admin: true
        },
        {
          name: '네이버톡 에이전트 관리',
          link: '/admin/naver/agents',
          icon: '',
          admin: true
        },
        {
          name: '네이버톡 템플릿 관리',
          link: '/admin/naver/templates',
          icon: '',
          admin: true
        }
      ]
    },
    {
      headerTitle: '연동 / 자동화',
      list: [
        {
          name: '마이사이트',
          icon: 'web',
          link: '/mysites',
          showAppLayout: false
        },
        {
          name: '내 앱',
          icon: 'build',
          link: '/me/apps',
          exact: '^\\/me\\/apps((?!\\/settle).)*$',
          showAppLayout: false
        },
        {
          name: 'SDK 다운로드',
          icon: 'get_app',
          link: '/sdk'
        },
        {
          name: '수익 내역',
          icon: 'monetization_on',
          link: '/me/apps/settle',
          exact: false,
          showAppLayout: false
        },
        {
          name: 'API Key',
          icon: 'vpn_key',
          link: '/credentials'
        },
        {
          name: 'Webhooks',
          icon: (
            <svg viewBox="0 0 24 24">
              <path
                style={{ fill: 'inherit' }}
                d="M10.46,19C9,21.07 6.15,21.59 4.09,20.15C2.04,18.71 1.56,15.84 3,13.75C3.87,12.5 5.21,11.83 6.58,11.77L6.63,13.2C5.72,13.27 4.84,13.74 4.27,14.56C3.27,16 3.58,17.94 4.95,18.91C6.33,19.87 8.26,19.5 9.26,18.07C9.57,17.62 9.75,17.13 9.82,16.63V15.62L15.4,15.58L15.47,15.47C16,14.55 17.15,14.23 18.05,14.75C18.95,15.27 19.26,16.43 18.73,17.35C18.2,18.26 17.04,18.58 16.14,18.06C15.73,17.83 15.44,17.46 15.31,17.04L11.24,17.06C11.13,17.73 10.87,18.38 10.46,19M17.74,11.86C20.27,12.17 22.07,14.44 21.76,16.93C21.45,19.43 19.15,21.2 16.62,20.89C15.13,20.71 13.9,19.86 13.19,18.68L14.43,17.96C14.92,18.73 15.75,19.28 16.75,19.41C18.5,19.62 20.05,18.43 20.26,16.76C20.47,15.09 19.23,13.56 17.5,13.35C16.96,13.29 16.44,13.36 15.97,13.53L15.12,13.97L12.54,9.2H12.32C11.26,9.16 10.44,8.29 10.47,7.25C10.5,6.21 11.4,5.4 12.45,5.44C13.5,5.5 14.33,6.35 14.3,7.39C14.28,7.83 14.11,8.23 13.84,8.54L15.74,12.05C16.36,11.85 17.04,11.78 17.74,11.86M8.25,9.14C7.25,6.79 8.31,4.1 10.62,3.12C12.94,2.14 15.62,3.25 16.62,5.6C17.21,6.97 17.09,8.47 16.42,9.67L15.18,8.95C15.6,8.14 15.67,7.15 15.27,6.22C14.59,4.62 12.78,3.85 11.23,4.5C9.67,5.16 8.97,7 9.65,8.6C9.93,9.26 10.4,9.77 10.97,10.11L11.36,10.32L8.29,15.31C8.32,15.36 8.36,15.42 8.39,15.5C8.88,16.41 8.54,17.56 7.62,18.05C6.71,18.54 5.56,18.18 5.06,17.24C4.57,16.31 4.91,15.16 5.83,14.67C6.22,14.46 6.65,14.41 7.06,14.5L9.37,10.73C8.9,10.3 8.5,9.76 8.25,9.14Z"
              />
            </svg>
          ),
          link: '/webhooks'
        },
        {
          name: '커머스자동화',
          isBeta: true,
          icon: <MdShoppingCartIcon />,
          link: '/commerce/hooks',
          showAppLayout: false
        },
        {
          name: '단축 URL',
          isBeta: true,
          icon: <HiLinkIcon />,
          link: '/short/campaigns'
        },
        {
          name: '첨부파일',
          icon: 'attachment',
          link: '/storage'
        },
        {
          name: '개발 문서',
          icon: 'description',
          link: 'https://developers.solapi.com/',
          newTab: true
          // showAppLayout: false
        },
        {
          name: '사용자 앱 관리',
          icon: '',
          link: '/admin/apps',
          exact: '^\\/admin\\/apps((?!\\/settle).)*$',
          admin: true
        },
        {
          name: '사용자 수익 내역',
          icon: '',
          link: '/admin/apps/settle',
          exact: false,
          admin: true
        },
        {
          name: '사용자 수익 내역 디테일',
          icon: '',
          link: '/admin/apps/settle/detail',
          exact: false,
          admin: true
        },
        {
          name: '사용자 마이사이트 관리',
          icon: '',
          link: '/admin/mysites',
          admin: true
        },
        {
          name: '사용자 단축 URL 캠페인',
          icon: '',
          link: '/admin/short/campaigns',
          admin: true
        },
        {
          name: '사용자 첨부파일 관리',
          icon: '',
          link: '/admin/storage',
          admin: true
        }
      ]
    },
    {
      headerTitle: '계정 / 회원',
      showAppLayout: false,
      admin: true,
      list: [
        // {
        //   name: '내 계정 목록',
        //   icon: 'supervised_user_circle',
        //   link: '/accounts'
        // },
        // {
        //   name: '계정 정보 관리',
        //   icon: 'supervisor_account',
        //   link: '/accounts/selected-account',
        //   authOnly: true
        // },
        // {
        //   name: '회원 정보 관리',
        //   icon: 'person',
        //   link: '/profile'
        // },
        {
          name: '화이트 리스트',
          icon: '',
          link: '/admin/white',
          admin: true
        },
        {
          name: '블랙 리스트',
          icon: '',
          link: '/admin/black',
          admin: true
        },
        {
          name: '전체 계정 관리',
          icon: '',
          link: '/admin/accounts',
          admin: true
        },
        {
          name: '계정 인증 관리',
          icon: '',
          link: '/admin/verifications',
          admin: true
        },
        {
          name: '전체 회원 관리',
          icon: '',
          link: '/admin/members',
          admin: true
        },
        {
          name: '마케터 관리',
          icon: '',
          link: '/admin/marketer',
          admin: true
        },
        {
          name: '플랜 관리',
          icon: '',
          link: '/admin/plans',
          admin: true
        },
        {
          name: '계정 구독 관리',
          icon: '',
          link: '/admin/subscriptions',
          admin: true
        }
      ]
    },
    {
      headerTitle: `전화문의 ${config?.support?.phoneNumber}`,
      showAppLayout: false,
      list: [
        {
          name: '서비스 가이드',
          icon: <MdHelpCenterIcon />,
          link: `https://solapi.com/guides`,
          newTab: true
        },
        {
          name: '앱스토어 바로가기',
          icon: 'apps',
          link: '/apps',
          newTab: true
        },
        {
          name: '회계 레코드 등록',
          icon: '',
          link: '/admin/accounting/items/new',
          admin: true
        },
        {
          name: '회계 레코드 목록',
          icon: '',
          link: '/admin/accounting/items',
          admin: true
        },
        {
          name: '회계 리포트',
          icon: '',
          link: '/admin/accounting/report',
          admin: true
        },
        {
          name: '기능 요청',
          icon: 'assignment_late',
          link: '/admin/feature',
          admin: true
        },
        {
          name: '상담 내역',
          link: '/admin/inquiries',
          admin: true
        }
      ]
    }
  ]
})

// 다른 프론트엔드 페이지에서 해당 부분 리렌더링을 하기 위해서 INIT을 handler로 뺌
const handler = {
  INIT: {
    reducer: state => state.set('menus', [].concat(initialState.get('menus')))
  }
}

handler.initLoadComponent = {
  reducer: state => {
    return state
      .set('loadComponents', [])
      .set('loadedComponents', [])
      .set('highlight', [])
  }
}

handler.addHighlight = {
  reducer: (state, action) => {
    const originHighlight = state.get('highlight') || []
    return state.set('highlight', [...originHighlight, action.payload])
  }
}

handler.setLoadComponent = {
  reducer: (state, action) => {
    const loadComponents = state.get('loadComponents')
    return state.set('loadComponents', loadComponents.concat(action.payload))
  }
}

handler.loadedComplete = {
  reducer: (state, action) => {
    const loadedComponents = state.get('loadedComponents')
    return state.set(
      'loadedComponents',
      loadedComponents.concat(action.payload)
    )
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
