import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import config from 'config'
import getCookieDomain from 'lib/getCookieDomain'
import logoutChannelTalk from 'lib/logoutChannelTalk'

const logout = ({ withRedirect = false } = {}) => {
  try {
    logoutChannelTalk()
    cookies.remove('CSAK', { path: '/', domain: getCookieDomain() })
    cookies.remove('CSAK', { path: '', domain: getCookieDomain() })
    sessionStorage.clear()
    if (withRedirect === true) {
      // move
      const { apiHost: configApiHost } = config
      const ahid = jwt.decode(cookies.get('AHID')) || {}
      const apiHost = ahid.apiHost || configApiHost
      window.location.href = `${apiHost}/oauth2/v1/logout`
    }
  } catch (error) {}
}

export default logout
