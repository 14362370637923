const merge = require('lodash/merge')

let config = {
  serviceName: '쿨에스엠에스',
  loginPath: '/coolapi/v1/login',
  homePage: 'https://coolsms.co.kr',
  mainTrackingID: `G-30RVMR6YB8`,
  support: {
    phoneNumber: '02-930-2266',
    email: 'contact@nurigo.net'
  },
  blocksender: '0806005653',
  supportPageHost: 'https://support.coolsms.co.kr',
  consolePageHost: 'https://console.coolsms.co.kr',
  guidePageUrl: 'https://guide.coolsms.co.kr',
  zendeskApiHost: 'https://coolsms.zendesk.com',
  zendeskNotice: '360001456732',
  developerPageHost: 'https://developers.coolsms.co.kr/',
  webhookDocumentPage: 'https://developers.coolsms.co.kr/references/webhook/',
  channelTalkPluginKey: '3c9e1261-c2e9-4c54-8363-9c95ed093821',
  shortUrlDomain: 'cools.ms',
  commonRequestUrl: 'https://api.coolsms.co.kr',
  shortCampaignPlanId: 'CA01PL240911032847096wQazib1W7Cg'
}

if (process.env.NODE_ENV === 'production') {
  const production = require('./production')
  config = merge(config, production)
}

if (process.env.NODE_ENV === 'development') {
  const development = require('./development')
  config = merge(config, development)
}

if (process.env.NODE_ENV === 'test') {
  const test = require('./test')
  config = merge(config, test)
}

if (process.env.REACT_APP_INTEGRATION === '1') {
  const integration = require('./integration')
  config = merge(config, integration)
}
module.exports = config
