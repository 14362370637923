// https://semver.org/ 형식으로 버전을 작성합니다.
// X.Y.Z
// X = 주 버전 : 업데이트 필수 (사용자에게 notify)
// Y = 부 버전 : 업데이트 권장
// Z = 패치 버전 : 업데이트 선택

// 모든 버전은 음이 아닌 정수이고 선행 0을 포함해서는 안 됩니다
// 1.0.1 (O) 1.01.1 (X)

// 패치 버전 Z(xyZ | x > 0)는 이전 버전과 호환되는 버그 수정만 도입된 경우 증가해야 합니다. 버그 수정은 잘못된 동작을 수정하는 내부 변경으로 정의됩니다.

// 부 버전 Y(xYz | x > 0)는 이전 버전과 호환되는 새로운 기능이 공개 API에 도입되는 경우 증가해야 합니다(MUST).
// 공개 API 기능이 더 이상 사용되지 않는 것으로 표시된 경우 증가해야 합니다(MUST).
// 개인 코드 내에서 상당한 새 기능이나 개선 사항이 도입되면 증가할 수 있습니다(MAY).
// 여기에는 패치 수준 변경 사항이 포함될 수 있습니다(MAY). 패치 버전은 부 버전이 증가할 때 0으로 재설정되어야 합니다.

// 이전 버전과 호환되지 않는 변경 사항이 공개 API에 도입되면 주 버전 X(Xyz | X > 0)를 증가시켜야 합니다(MUST).
// 여기에는 사소한 패치 수준 변경 사항도 포함될 수 있습니다(MAY).
// 패치 및 부 버전은 주 버전이 증가할 때 0으로 재설정되어야 합니다.

import last from 'lodash/last'
import compareVersions from 'compare-versions'
import moment from 'moment'
import { isEmptyString } from 'lib/detectType'

export const getUserVersion = () => {
  try {
    let userVersion = window.localStorage.getItem('appVersion')
    if (isEmptyString(userVersion)) {
      userVersion = updateUserVersion()
    }
    return userVersion
  } catch (error) {
    return getCurrentVersion()
  }
}

export const updateUserVersion = () => {
  const latestVersion = getLatestVersion()
  window.localStorage.setItem('appVersion', latestVersion)
  return latestVersion
}

export const getCurrentVersion = () => {
  return process.env.REACT_APP_VERSION
}

export const getLatestVersion = () => {
  const version = Object.keys(versionData).sort(compareVersions)
  return last(version)
}

const detectNeedUpdate = (userVersion, latestVersion) => {
  let isNeedUpdate
  if (userVersion === latestVersion) {
    isNeedUpdate = false
  }
  const isPrimaryUpdated =
    isNeedUpdate !== false &&
    getPrimary(userVersion) < getPrimary(latestVersion)
  const isSecondaryUpdated =
    isNeedUpdate !== false &&
    getPrimary(userVersion) <= getPrimary(latestVersion) &&
    getSecondary(userVersion) < getSecondary(latestVersion)
  const isFetchUpdated =
    isNeedUpdate !== false &&
    getPrimary(userVersion) <= getPrimary(latestVersion) &&
    getSecondary(userVersion) <= getSecondary(latestVersion) &&
    getFetch(userVersion) < getFetch(latestVersion)
  isNeedUpdate = isPrimaryUpdated || isSecondaryUpdated || isFetchUpdated
  return {
    isNeedUpdate,
    isPrimaryUpdated,
    isSecondaryUpdated,
    isFetchUpdated
  }
}

export const checkUpdate = () => {
  const userVersion = getUserVersion()
  const latestVersion = getLatestVersion()
  const { isNeedUpdate, isPrimaryUpdated, isSecondaryUpdated, isFetchUpdated } =
    detectNeedUpdate(userVersion, latestVersion)
  if (isNeedUpdate) {
    updateUserVersion()
  }
  return {
    needUpdate: isNeedUpdate,
    currentVersion: {
      version: userVersion,
      ...versionData[userVersion]
    },
    latestVersion: {
      version: latestVersion,
      ...versionData[latestVersion]
    },
    isPrimaryUpdated,
    isSecondaryUpdated,
    isFetchUpdated
  }
}

export const getPrimary = version => {
  return Number(version.split('.')[0])
}

export const getSecondary = version => {
  return Number(version.split('.')[1])
}

export const getFetch = version => {
  return Number(version.split('.')[2])
}

const versionData = {
  '0.1.0': {
    dateUpdated: moment('19.01.01', 'YYYY.MM.DD').toISOString(),
    description: []
  },
  '1.0.0': {
    dateUpdated: moment('22.08.18', 'YYYY.MM.DD').toISOString(),
    description: [
      `본인인증 및 재직인증 필수화`,
      `계정정보 조회 페이지 수정`,
      `시작하기 페이지 수정`,
      `사용자 UI 버전 체크 도입`,
      `버그 수정 및 안정성 향상`
    ]
  },
  '1.0.1': {
    dateUpdated: moment('22.08.24', 'YYYY.MM.DD').toISOString(),
    description: [`버그 수정`]
  },
  '1.0.2': {
    dateUpdated: moment('22.08.25', 'YYYY.MM.DD').toISOString(),
    description: [`발신번호 본인인증 변경`]
  },
  '1.0.3': {
    dateUpdated: moment('22.08.31', 'YYYY.MM.DD').toISOString(),
    description: [
      `카카오 알림톡 채널추가 광고문구 변경`,
      `RCS 브랜드, 전용계좌, 후불신청 시 사업자정보 등록폼 변경`,
      `후불신청 UI 수정`,
      `발신번호 UI 수정`,
      `API Key 페이지에서 시그니처 생성예제`,
      `버그 수정 및 사용성 개선`
    ]
  },
  '1.0.4': {
    dateUpdated: moment('22.09.02', 'YYYY.MM.DD').toISOString(),
    description: [`레이아웃 회원정보 변경`, `잔액이전 계정선택 목록 버그 수정`]
  },
  '1.0.5': {
    dateUpdated: moment('22.09.05', 'YYYY.MM.DD').toISOString(),
    description: [`카카오 템플릿 의견 남기기 버그 수정`]
  },
  '1.0.6': {
    dateUpdated: moment('22.09.06', 'YYYY.MM.DD').toISOString(),
    description: [`사업자정보 제출 폼 변경`]
  },
  '1.0.7': {
    dateUpdated: moment('22.09.21', 'YYYY.MM.DD').toISOString(),
    description: [`퍼플북 친구톡 버튼 추가 버그 수정`]
  },
  '1.1.0': {
    dateUpdated: moment('22.11.02', 'YYYY.MM.DD').toISOString(),
    description: [`카카오 채널 그룹 수락 / 거절 기능 추가`]
  },
  '1.1.1': {
    dateUpdated: moment('22.11.10', 'YYYY.MM.DD').toISOString(),
    description: [`카카오 알림톡 템플릿 검수 취소 기능 추가`]
  },
  '1.2.0': {
    dateUpdated: moment('22.11.10', 'YYYY.MM.DD').toISOString(),
    description: [`회원가입 시 간편인증 추가`]
  },
  '1.3.0': {
    dateUpdated: moment('22.12.13', 'YYYY.MM.DD').toISOString(),
    description: [
      `카카오 알림톡 템플릿 생성, 수정 시 바로연결 버튼 지원`,
      `카카오 알림톡 템플릿 생성, 수정 시 아이템 하이라이트 지원 `
    ]
  },
  '1.3.1': {
    dateUpdated: moment('22.12.22', 'YYYY.MM.DD').toISOString(),
    description: [
      `계정 나가기 이후 버그 수정`,
      `카카오 가이드 편의성 수정`,
      `채널연동 오류 시 가이드 링크추가`,
      `카카오템플릿 하이라이트 본문 최대 200자 제한`,
      `이 외 기타 버그수정`
    ]
  },
  '1.4.0': {
    dateUpdated: moment('23.01.02', 'YYYY.MM.DD').toISOString(),
    description: [`알림톡 템플릿 자세히보기 리뉴얼`, `기타버그수정`]
  },
  '1.4.1': {
    dateUpdated: moment('23.01.03', 'YYYY.MM.DD').toISOString(),
    description: [`알림톡 템플릿 의견 내역 전체조회`]
  },
  '1.4.2': {
    dateUpdated: moment('23.01.06', 'YYYY.MM.DD').toISOString(),
    description: [`알림톡 템플릿 수정, 생성 폼 개선`]
  },
  '1.4.3': {
    dateUpdated: moment('23.01.10', 'YYYY.MM.DD').toISOString(),
    description: [`메모리 최적화`, `읽지 않은 알림 조회 변경`]
  },
  '1.4.4': {
    dateUpdated: moment('23.01.12', 'YYYY.MM.DD').toISOString(),
    description: [
      `퍼플북 그룹 팝업 버튼추가`,
      `멤버 목록 조회 버그 수정`,
      `메모리 최적화`
    ]
  },
  '1.4.5': {
    dateUpdated: moment('23.01.18', 'YYYY.MM.DD').toISOString(),
    description: [`알림 내용의 링크 첨부 시 활성화`]
  },
  '1.4.6': {
    dateUpdated: moment('23.01.18', 'YYYY.MM.DD').toISOString(),
    description: [`테이블, 검색창 메모리 최적화`]
  },
  '1.4.7': {
    dateUpdated: moment('23.01.19', 'YYYY.MM.DD').toISOString(),
    description: [`메뉴 즐겨찾기 기능 추가`]
  },
  '1.4.8': {
    dateUpdated: moment('23.01.22', 'YYYY.MM.DD').toISOString(),
    description: [`알림톡 템플릿 이미지 메이커 베타 추가`, `버그수정`]
  },
  '1.4.9': {
    dateUpdated: moment('23.01.24', 'YYYY.MM.DD').toISOString(),
    description: [`알림톡 템플릿 이미지 메이커에 기본 아이콘 추가`]
  },
  '1.5.0': {
    dateUpdated: moment('23.01.25', 'YYYY.MM.DD').toISOString(),
    description: [`알림톡 템플릿 이미지 메이커 폰트 수정`]
  },
  '1.5.1': {
    dateUpdated: moment('23.01.26', 'YYYY.MM.DD').toISOString(),
    description: [`템플릿 목록 디자인 수정`]
  },
  '1.6.0': {
    dateUpdated: moment('23.02.01', 'YYYY.MM.DD').toISOString(),
    description: [`알림톡 템플릿 삭제 기능 추가`, `2차 인증 개선`]
  },
  '1.7.0': {
    dateUpdated: moment('23.02.07', 'YYYY.MM.DD').toISOString(),
    description: [`일일 발송량 페이지 개선`, `멤버 계정 알림 무시 기능 추가`]
  },
  '1.7.1': {
    dateUpdated: moment('23.02.08', 'YYYY.MM.DD').toISOString(),
    description: [`발신번호 사용자 UI 개선`]
  },
  '1.7.2': {
    dateUpdated: moment('23.02.15', 'YYYY.MM.DD').toISOString(),
    description: [`발송내역 자세히보기 팝업 기능 개선`]
  },
  '1.7.3': {
    dateUpdated: moment('23.02.16', 'YYYY.MM.DD').toISOString(),
    description: [`퍼플북 발송 후 변경사항 없다면 재발송 시 경고`]
  },
  '1.7.4': {
    dateUpdated: moment('23.04.20', 'YYYY.MM.DD').toISOString(),
    description: [
      `네이버 템플릿 수정 시 버그 fix`,
      `세금계산서 이메일 유효성검사 정규식 수정`,
      `카카오 알림톡 템플릿 강조표기형 유효성검사 정규식 수정`
    ]
  },
  '1.7.5': {
    dateUpdated: moment('23.05.16', 'YYYY.MM.DD').toISOString(),
    description: [
      `마이사이트 계정별 수익금이 기본 수익금을 초과할 수 있도록 변경`,
      `마이사이트 생성 폼 일부 변경`
    ]
  },
  '1.7.6': {
    dateUpdated: moment('23.05.26', 'YYYY.MM.DD').toISOString(),
    description: [`마이사이트 내부 계정에게 관리자권한 부여 기능`]
  },
  '1.7.7': {
    dateUpdated: moment('23.06.08', 'YYYY.MM.DD').toISOString(),
    description: [`알림톡 템플릿 버튼링크 유효성 검사 추가`]
  },
  '1.8.0': {
    dateUpdated: moment('23.06.16', 'YYYY.MM.DD').toISOString(),
    description: [
      `퍼플북 디자인 수정`,
      `컴포넌트 강조 디자인 수정`,
      `알림톡 템플릿 변수 유효성 검사 추가`,
      `알림톡 반려사유 의견남기기 최대 2000자`
    ]
  },
  '1.8.1': {
    dateUpdated: moment('23.06.22', 'YYYY.MM.DD').toISOString(),
    description: [`전송내역 시간대 검색`, `문자보내기 예약 방식 변경`]
  },
  '1.8.2': {
    dateUpdated: moment('23.06.26', 'YYYY.MM.DD').toISOString(),
    description: [`주소록 폴더에 주소록 추가 버그 수정`]
  },
  '1.8.3': {
    dateUpdated: moment('23.07.04', 'YYYY.MM.DD').toISOString(),
    description: [`RCS 슬라이드 추가 UI 변경`]
  },
  '1.8.4': {
    dateUpdated: moment('23.07.05', 'YYYY.MM.DD').toISOString(),
    description: [`발신번호 목록 UI 변경`]
  },
  '1.8.5': {
    dateUpdated: moment('23.07.06', 'YYYY.MM.DD').toISOString(),
    description: [`퍼플북 설정 위치 변경`]
  },
  '1.8.6': {
    dateUpdated: moment('23.07.10', 'YYYY.MM.DD').toISOString(),
    description: [`퍼플북 최근 발송 및 최근 사용한 예약 시간 유지`]
  },
  '1.8.7': {
    dateUpdated: moment('23.07.11', 'YYYY.MM.DD').toISOString(),
    description: [
      `퍼플북 최근 사용 데이터 저장 변경`,
      `수신자 텍스트 소량 추가 시 즉시반영`
    ]
  },
  '1.8.8': {
    dateUpdated: moment('23.07.12', 'YYYY.MM.DD').toISOString(),
    description: [`사용자 전용 충전 금액 견적`]
  },
  '1.8.9': {
    dateUpdated: moment('23.07.18', 'YYYY.MM.DD').toISOString(),
    description: [
      `RCS 미리보기 광고 여부 반영`,
      `문자보내기 메시지 종류 디자인 변경`
    ]
  },
  '1.9.0': {
    dateUpdated: moment('23.07.25', 'YYYY.MM.DD').toISOString(),
    description: [`커머스 자동화 Beta 버전 배포`]
  },
  '1.9.1': {
    dateUpdated: moment('23.08.02', 'YYYY.MM.DD').toISOString(),
    description: [`앱스토어 권한 승인 페이지 변경`]
  },
  '1.9.2': {
    dateUpdated: moment('23.08.03', 'YYYY.MM.DD').toISOString(),
    description: [`앱스토어 권한 철회 기능 추가`]
  },
  '1.9.3': {
    dateUpdated: moment('23.08.04', 'YYYY.MM.DD').toISOString(),
    description: [`홈페이지 일부 오류에 대한 해결방법 버튼 추가`]
  },
  '1.9.4': {
    dateUpdated: moment('23.08.08', 'YYYY.MM.DD').toISOString(),
    description: [`bugfix`]
  },
  '1.9.5': {
    dateUpdated: moment('23.08.14', 'YYYY.MM.DD').toISOString(),
    description: [`신규 로고 및 색상 적용`]
  },
  '1.9.6': {
    dateUpdated: moment('23.08.29', 'YYYY.MM.DD').toISOString(),
    description: [`잔액 관리 페이지 UI 변경`]
  },
  '1.9.7': {
    dateUpdated: moment('23.09.08', 'YYYY.MM.DD').toISOString(),
    description: [
      `계정 나가기 및 계정 삭제 개선`,
      `API Key 최근 사용처 조회 기능 추가`
    ]
  },
  '1.9.8': {
    dateUpdated: moment('23.09.14', 'YYYY.MM.DD').toISOString(),
    description: [`Bug Fix`]
  },
  '1.9.9': {
    dateUpdated: moment('23.09.15', 'YYYY.MM.DD').toISOString(),
    description: [`Bug Fix`, `RCS 관련 미리보기 수정`]
  },
  '1.9.10': {
    dateUpdated: moment('23.09.27', 'YYYY.MM.DD').toISOString(),
    description: [`웹훅 최근 사용 이력 표기`]
  },
  '1.10.0': {
    dateUpdated: moment('23.09.27', 'YYYY.MM.DD').toISOString(),
    description: [
      `마이사이트 생성 폼 변경`,
      `마이사이트 파비콘 변경`,
      `앱 및 마이사이트 사용량 조회 추가`
    ]
  },
  '1.10.1': {
    dateUpdated: moment('23.10.16', 'YYYY.MM.DD').toISOString(),
    description: [`서비스 별 채널톡 분리`]
  },
  '1.10.2': {
    dateUpdated: moment('23.10.20', 'YYYY.MM.DD').toISOString(),
    description: [`충전 내역 변경`]
  },
  '1.10.3': {
    dateUpdated: moment('23.10.24', 'YYYY.MM.DD').toISOString(),
    description: [`카카오 가이드 페이지 디자인 변경`]
  },
  '1.11.0': {
    dateUpdated: moment('23.11.06', 'YYYY.MM.DD').toISOString(),
    description: [`서비스 튜토리얼 추가`]
  },
  '1.11.1': {
    dateUpdated: moment('23.11.09', 'YYYY.MM.DD').toISOString(),
    description: [`Bug Fix`]
  },
  '1.11.2': {
    dateUpdated: moment('23.11.10', 'YYYY.MM.DD').toISOString(),
    description: [`수신 거부 페이지 리뉴얼`]
  },
  '1.11.3': {
    dateUpdated: moment('23.11.13', 'YYYY.MM.DD').toISOString(),
    description: [`팝업 디자인 변경`]
  },
  '1.11.4': {
    dateUpdated: moment('23.11.20', 'YYYY.MM.DD').toISOString(),
    description: [`간편인증창 모바일 지원`, `Bug Fix`]
  },
  '1.12.0': {
    dateUpdated: moment('23.11.24', 'YYYY.MM.DD').toISOString(),
    description: [`메시지 목록 검색 디자인 변경`]
  },
  '1.12.1': {
    dateUpdated: moment('23.11.29', 'YYYY.MM.DD').toISOString(),
    description: [`Bug Fix`]
  },
  '1.12.2': {
    dateUpdated: moment('23.12.14', 'YYYY.MM.DD').toISOString(),
    description: [
      `커머스 자동화에서 외부 자동화로 내보내기 지원`,
      `커머스 자동화 액션 웹훅 지원`
    ]
  },
  '1.12.3': {
    dateUpdated: moment('23.12.28', 'YYYY.MM.DD').toISOString(),
    description: [`웹훅 페이지 디자인 리뉴얼`]
  },
  '1.12.4': {
    dateUpdated: moment('24.01.02', 'YYYY.MM.DD').toISOString(),
    description: [`회원 API Key 소유자 이전 기능`]
  },
  '1.12.5': {
    dateUpdated: moment('24.01.08', 'YYYY.MM.DD').toISOString(),
    description: [`커머스 자동화 카카오 알림톡 발송 기능 추가`]
  },
  '1.12.6': {
    dateUpdated: moment('24.02.01', 'YYYY.MM.DD').toISOString(),
    description: [
      `퍼플북 카카오 관련 UI 변경`,
      `메시지 목록 다운로드 UI변경`,
      `후불 신청폼 UI 변경`,
      `전송 내역 그룹 요약 사용자 화면 변경`
    ]
  },
  '1.13.0': {
    dateUpdated: moment('24.02.07', 'YYYY.MM.DD').toISOString(),
    description: [`short url 생성 기능 추가`]
  },
  '1.13.1': {
    dateUpdated: moment('24.03.06', 'YYYY.MM.DD').toISOString(),
    description: [`API 인증정보 조회 UI 변경`]
  },
  '1.13.2': {
    dateUpdated: moment('24.03.07', 'YYYY.MM.DD').toISOString(),
    description: [`메시지 그룹 목록 조회 UI 변경`]
  },
  '1.13.3': {
    dateUpdated: moment('24.03.26', 'YYYY.MM.DD').toISOString(),
    description: [`알림톡 템플릿 불일치 비주얼라이저 추가`]
  },
  '1.13.4': {
    dateUpdated: moment('24.04.15', 'YYYY.MM.DD').toISOString(),
    description: [`커머스 변수 추가`, `플랜 추가`]
  },
  '1.13.5': {
    dateUpdated: moment('24.04.18', 'YYYY.MM.DD').toISOString(),
    description: [`메시지 미리보기 디자인 개선`]
  },
  '1.13.6': {
    dateUpdated: moment('24.05.03', 'YYYY.MM.DD').toISOString(),
    description: [`메시지 실패 진단 컨테이너 추가`]
  },
  '1.13.7': {
    dateUpdated: moment('24.05.21', 'YYYY.MM.DD').toISOString(),
    description: [`커머스 자동화 단계 및 동작 실행 단위 추가`]
  },
  '1.14.0': {
    dateUpdated: moment('24.06.18', 'YYYY.MM.DD').toISOString(),
    description: [`Short URL 베타 퍼플북, 메시지 미리보기 적용`]
  },
  '1.14.1': {
    dateUpdated: moment('24.06.20', 'YYYY.MM.DD').toISOString(),
    description: [`프론트엔드 최적화, UI LIVE 테스팅 추가`]
  },
  '1.14.2': {
    dateUpdated: moment('24.06.24', 'YYYY.MM.DD').toISOString(),
    description: [`엑셀 서식 유지 옵션 추가`]
  },
  '1.14.3': {
    dateUpdated: moment('24.08.26', 'YYYY.MM.DD').toISOString(),
    description: [`커머스 자동화 고급옵션 추가`]
  },
  '1.14.4': {
    dateUpdated: moment('24.09.04', 'YYYY.MM.DD').toISOString(),
    description: [`커머스 자동화 템플릿 지원`]
  }
}

export default {
  getCurrentVersion
}
