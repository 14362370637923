const cookies = require('js-cookie')
const jwt = require('jsonwebtoken')

const appHomeData = jwt.decode(cookies.get('AHID')) || {}
const apiHost = appHomeData.apiHost || 'https://api.coolsms.co.kr'
const pageHost = appHomeData.pageHost || 'https://www.coolsms.co.kr'

const config = {
  pageHost,
  consolePageHost: 'https://console.coolsms.co.kr',
  apiHost,
  gaTrackingID: 'UA-58654284-1',
  ga4TrackingID: `G-30RVMR6YB8`,
  kgMID: 'coolsmsias',
  kgApiKey: '8fa81bb0dc12a6a54a8b0c81270a209f'
}
module.exports = config
